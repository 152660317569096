import { Field, useFormikContext } from "formik";
import ViewContainer from "../../components/ViewContainer/ViewContainer";
import { Post } from "../../types/content-types";
import { SubFormProps } from "../types";
import { useEditorField } from "./useEditorField";
import CircleScaleBlock from "../../components/Charts/CircleScale/CircleScaleBlock";
import { Checkbox, Select, TextField } from "../Components";
import styles from "../Components.module.scss";

export default function CircleScaleForm({
  index,
  nestedIndex = 0,
  isNested = false,
}: SubFormProps) {
  const { setFieldValue, handleChange } = useFormikContext<Post>();

  const { fieldName, blockValue } = useEditorField({
    index,
    nestedIndex,
    isNested,
  });

  const sizeOptions = [
    { id: "auto", title: "Автоматический" },
    { id: "small", title: "Маленький" },
    { id: "medium", title: "Средний" },
    { id: "large", title: "Большой" },
  ];

  const name = fieldName("circle_scale");

  const value = blockValue?.circle_scale;

  const error = !value?.min_value || !value?.middle_value || !value?.max_value;

  return (
    <div>
      <ViewContainer>
        {blockValue && <CircleScaleBlock contentBlock={blockValue} />}
      </ViewContainer>
      <div className={styles.elementContainer}>
        <Checkbox
          title="Цветная шкала"
          isChecked={!!value?.is_colored}
          handleCheckboxChange={() =>
            setFieldValue(
              `${name}.is_colored`,
              !blockValue?.circle_scale?.is_colored
            )
          }
        />
        <Field
          as={Select}
          onChange={handleChange}
          options={sizeOptions}
          name={`${name}.size`}
          label="Размер диаграммы"
          value={value?.size}
          isChooseOptionFirst={false}
        />
        <TextField
          label="Минимальное значение"
          onChange={handleChange}
          value={value?.min_value}
          name={`${name}.min_value`}
          type="number"
        />
        <TextField
          label="Среднее значение"
          onChange={handleChange}
          value={value?.middle_value}
          name={`${name}.middle_value`}
          type="number"
        />
        <TextField
          label="Максимальное значение"
          onChange={handleChange}
          value={value?.max_value}
          name={`${name}.max_value`}
          type="number"
        />
        {error && (
          <div className={styles.error}>Поля не должны быть пустыми</div>
        )}
      </div>
    </div>
  );
}
