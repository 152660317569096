import { useState, useEffect, useMemo } from 'react';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import { Feature } from 'ol';
import { FeatureCollection } from 'geojson';

interface UseWFSLayerProps {
  url: string;
  layer: {
    workspace: string;
    layer: string;
  };
  sortBy?: string | null;
  sortOrder?: 'asc' | 'desc';
}

const useWFSLayer = ({ url, layer, sortBy, sortOrder = 'desc' }: UseWFSLayerProps) => {
  const [geojson, setGeojson] = useState<FeatureCollection | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [min, setMin] = useState<number | null>(null);
  const [max, setMax] = useState<number | null>(null);
  
  const source = useMemo(() => new VectorSource(), []);

  const layer_url = useMemo(() => {
    if (!layer.workspace || !layer.layer) return '';
    let urlString = `${url}${layer.workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layer.workspace}%3A${layer.layer}&maxFeatures=10000&outputFormat=application%2Fjson`;
    
    if (sortBy) {
      urlString += `&SORTBY=${sortBy}+${sortOrder}`;
    }
    return urlString;
  }, [url, layer.workspace, layer.layer, sortBy, sortOrder]);

  useEffect(() => {
    if (!layer_url) return;

    const fetchWFSData = async () => {
      setLoading(true);
      try {
        const response = await fetch(layer_url);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const geojsonData: FeatureCollection = await response.json();
        setGeojson(geojsonData);

        const features = new GeoJSON().readFeatures(geojsonData, {
          featureProjection: 'EPSG:3857',
        });

        source.clear();
        source.addFeatures(features as Feature[]);

        // Вычисление min/max по полю sortBy после загрузки данных
        if (sortBy) {
          const values = geojsonData.features
            .map((feature) => {
              const rawValue = feature.properties?.[sortBy];
              const numValue = Number(rawValue);
              return isNaN(numValue) ? null : numValue; // Исключаем NaN
            })
            .filter((value): value is number => value !== null); // Фильтруем валидные числа

          if (values.length > 0) {
            setMin(Math.min(...values));
            setMax(Math.max(...values));
          }
        }

      } catch (error) {
        console.error('Error fetching WFS data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchWFSData();
  }, [layer_url, source, sortBy]);

  return {
    layer_url,
    geojson,
    source,
    loading, // Флаг загрузки данных
    min,
    max,
  };
};

export default useWFSLayer;
