import React, { useEffect, useState } from "react";
import styles from "./WindowManagement.module.scss";
import MenuButton from "../MenuButton/MenuButton";
import { useMenuEdit } from "../../hooks/useMenuEditConfig";
import ContentManagement from "./ContentManagement/ContentManagement";
import NewsManagement from "./NewsManagement/NewsManagement";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";

export type Type = "content" | "news";

const WindowManagement: React.FC = () => {
  const [managementType, setManagementType] = useState<Type>("content");
  const navigate = useNavigate();

  const { type } = useParams();

  useEffect(() => {
    if (type === "news") {
      setManagementType(type);
    }
    // eslint-disable-next-line
  }, []);

  const handleTypeChange = (type: Type) => {
    if (type !== managementType) {
      setManagementType(type);
      navigate("/management");
    }
  };

  const { menuEditOptions, handleMenuEditSelect } = useMenuEdit();

  const managements = {
    content: <ContentManagement />,
    news: <NewsManagement />,
  };

  return (
    <div className={styles.container}>
      <MenuButton
        options={menuEditOptions}
        onSelect={handleMenuEditSelect}
        selectedOption="management"
        style={{
          position: "absolute",
          left: "-300px",
        }}
      />

      <div className={styles.menu}>
        <div
          className={classNames(styles.menuButton, {
            [styles.active]: managementType === "content",
          })}
          onClick={() => handleTypeChange("content")}
        >
          Контент
        </div>
        <div
          className={classNames(styles.menuButton, {
            [styles.active]: managementType === "news",
          })}
          onClick={() => handleTypeChange("news")}
        >
          Новости
        </div>
      </div>

      {managements[managementType]}
    </div>
  );
};

export default WindowManagement;
