import React, { useCallback } from 'react';
import { RLayerVector, RStyle, RFeature } from 'rlayers';
import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import { hexToRgba } from "../utils";
import { LineString, MultiLineString, Geometry } from 'ol/geom';
import { Stroke, Style } from "ol/style";
import { FeatureLike } from 'ol/Feature';

interface ArcLayerProps {
  url: string;
  layer: any;
  order: number;
}

/**
 * Функция для генерации арки между двумя точками с изменяющимся направлением
 */
const generateArc = (coordinates: number[][], bendFactor = 0.3, bendDirection = 1): LineString | null => {
    if (coordinates.length < 2) return null;
  
    const [start, end] = coordinates;
  
    // Вычисляем расстояние между точками
    const dx = end[0] - start[0];
    const dy = end[1] - start[1];
    const distance = Math.sqrt(dx * dx + dy * dy);
  
    const arcHeight = Math.max(distance * bendFactor, 0.1); // Чем больше, тем выше арка
  
    const numPoints = 50; // Можно увеличить до 100+
  
    const arcPoints = [];
    for (let i = 0; i <= numPoints; i++) {
      const t = i / numPoints; // Интерполяция от 0 до 1
  
      // Линейная интерполяция координат
      const x = start[0] + t * dx;
      const y = start[1] + t * dy;
  
      // Добавляем подъём (перпендикуляр к линии), учитывая направление изгиба
      const offsetX = -dy * (Math.sin(Math.PI * t) * arcHeight * bendDirection) / distance;
      const offsetY = dx * (Math.sin(Math.PI * t) * arcHeight * bendDirection) / distance;
  
      arcPoints.push([x + offsetX, y + offsetY]);
    }
  
    return new LineString(arcPoints);
  };  

/**
 * Функция для преобразования геометрии в арки (без постоянного обновления)
 */
const createArcGeometry = (feature: Feature): Geometry | null => {
    if (feature.get('_arcGeometry')) {
      return feature.getGeometry() || null; // Явно возвращаем null, если геометрия отсутствует
    }
  
    const geometry = feature.getGeometry();
    if (!geometry) return null; // Проверяем undefined и возвращаем null
  
    let newGeometry: Geometry | null = null;

    let bendDirection = feature.getProperties()["benddirection"];
  
    if (geometry instanceof LineString) {
      newGeometry = generateArc(geometry.getCoordinates(), 0.2, bendDirection);
    } else if (geometry instanceof MultiLineString) {
      const arcLines = geometry.getLineStrings().map((line) => generateArc(line.getCoordinates(), 0.2, bendDirection));
      newGeometry = new MultiLineString(arcLines.map((arc) => arc?.getCoordinates() || []));
    }
  
    if (newGeometry) {
      feature.set('_arcGeometry', true); // Помечаем, что арка установлена
      feature.setGeometry(newGeometry); // Устанавливаем арку один раз
    }
  
    return newGeometry;
  };

const ArcLayer: React.FC<ArcLayerProps> = ({ url, layer, order }) => {
  
  const createFeatureStyleCallback = useCallback((feature: FeatureLike) => {

    createArcGeometry(feature as Feature);

    const color_fill_hex = feature.getProperties().color_hex?.trim() || "#7F7F7F";
    const opacity_fill = layer.marker?.opacity || Number(feature.getProperties().opacity) || 100;
    const outlineWidth = layer.marker?.outline_width || Number(feature.getProperties().width) * 3 || 4;
    const color_fill = hexToRgba(color_fill_hex, opacity_fill);

    return [
      new Style({
        stroke: new Stroke({
          color: "white", // Обводка (чуть шире)
          width: outlineWidth + 2,
          lineCap: "butt",
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: color_fill, // Основной цвет линии
          width: outlineWidth,
          lineCap: "butt",
        }),
      }),
    ];
  }, [layer.marker]);
  

  return (
    <RLayerVector
      zIndex={order}
      format={new GeoJSON({ featureProjection: 'EPSG:4326' })}
      url={url}
      visible={layer.visible}
      style={createFeatureStyleCallback}
    >
    </RLayerVector>
  );
};

export default ArcLayer;
