import React, { useEffect, useState } from "react";
import styles from "./NewsWindow.module.scss";
import { News } from "../../types/content-types";
import { getNewsList } from "../../common/ApiService";
import NewsBlock from "../NewsBlock/NewsBlock";
import CircleLoader from "../Loaders/CircleLoader/CircleLoader";

export type NewsWindowProps = {};

const NewsWindow: React.FC<NewsWindowProps> = () => {
  const [news, setNews] = useState<News[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchNews = async () => {
    try {
      setIsLoading(true);
      const newsList = await getNewsList();
      setNews(newsList);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  return (
    <div className={styles.container}>
      {isLoading && <CircleLoader />}
      <div className={styles.mainTitle}>НОВОСТИ</div>
      <div className={styles.newsContainer}>
        {news.map((news, index) => {
          return <NewsBlock key={index} {...news} />;
        })}
      </div>
    </div>
  );
};

export default NewsWindow;
