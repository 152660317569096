import React from "react";
import styles from "./GapScale.module.scss";
import { ContentBlock } from "../../../types/content-types";
import ContentBlockBase from "../../ContentBlockBase";

function GapScale({ contentBlock }: { contentBlock: ContentBlock }) {
  if (contentBlock?.scale) {
    const { data, legend } = contentBlock?.scale;
    return (
      <ContentBlockBase contentBlock={contentBlock}>
        <div className={styles.container}>
          {data.map((item, index) => (
            <div
              key={index}
              className={styles.element}
              style={{
                backgroundColor: item.color,
              }}
            >
              <div
                className={styles.title}
                style={{ color: legend[index].color }}
              >
                {legend[index].title ?? ""}
              </div>
            </div>
          ))}
        </div>
      </ContentBlockBase>
    );
  }
  return null;
}

export default React.memo(GapScale);
