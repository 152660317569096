import React, { useCallback } from 'react';
import { RLayerVector, RStyle } from 'rlayers';
import { Feature } from 'ol';
import useWFSLayer from './useWFSLayer';
import { GeoJSON } from 'ol/format';
import { hexToRgba } from "../utils";

interface PolygonLayerProps {
  url: string;
  layer: any;
  order: number;
}

const LineLayer: React.FC<PolygonLayerProps> = ({ url, layer, order }) => {

  const getLineColor = (f: Feature) => {

    const color_fill_hex = f.getProperties().color_hex?.trim() || "#7F7F7F";

    let opacity_fill = layer.marker?.opacity;
    if (!opacity_fill) {
      opacity_fill = Number(f.getProperties().opacity) || 100;
    }
    
    const color_fill = hexToRgba(color_fill_hex, opacity_fill);

    let outlineWidth = layer.marker?.outline_width;

    if (!outlineWidth) {
      outlineWidth = Number(f.getProperties().width) || 4;
    }

    return <RStyle.RStroke width={outlineWidth} color={color_fill} />;
  };

  return (
    <RLayerVector
      zIndex={order}
      format={new GeoJSON({ featureProjection: 'EPSG:4326' })}
      url={url}
      visible={layer.visible}
    >
      <RStyle.RStyle render={useCallback(getLineColor, [])} />
    </RLayerVector>
  );
};

export default LineLayer;
