import { formatDateTime, isISO8601Date } from "../../common/utils";
import { News } from "../../types/content-types";
import styles from "./NewsBlock.module.scss";
import parse from "html-react-parser";

const NewsBlock: React.FC<News> = ({ header, created, content }) => {
  const prepareDate = isISO8601Date(created)
    ? formatDateTime(created, "ddmmyyyy")
    : created;

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.date}>{prepareDate}</div>
        <div className={styles.title}>{header}</div>
      </div>
      <div className={styles.contentContainer}>{parse(content)}</div>
    </div>
  );
};

export default NewsBlock;
