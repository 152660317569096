import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Chart,
  Title,
  Tooltip,
  TooltipItem,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
  ChartEvent,
  ActiveElement,
} from "chart.js";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import { useState } from "react";
import React from "react";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
  ChartDataLabels
);

type DatasetDonutChart = {
  /** Значения элементов диаграммы */
  data: number[];
  /** Цвета элементов диаграммы */
  color: string[];
  /** Тексты подсказки элементов диаграммы */
  tooltip?: string[];
};

export type DonutChartProps = {
  /** Заголовок диаграммы */
  title?: string;
  /** Радиус диаграммы в пикселях */
  radius?: number;
  /** Вырез внутри диаграммы */
  cutout?: string;
  /** Подпись каждого элемента диаграммы сверху */
  labels?: string[];
  /** Набор данных диаграммы */
  datasets: DatasetDonutChart[];
  /** Значения элементов диаграммы, которые не будут отображаться */
  not_displayed_data?: number[];
  /** Надпись в центре диаграммы*/
  center_text?: string;
  /** Размер шрифта надписи в центре*/
  /** default: 24 */
  center_text_font_size?: number;
  /** Значение задает отношение ширины к высоте диаграммы */
  /** default: 1 */
  aspect?: number;
  /** Включение отображения значений на элементами */
  /** default: true */
  is_data_labels_enabled?: boolean;
  /** Добавляется подпись для значений над элементами */
  data_labels_description?: string;
  /** Размер шрифта значения над элементами */
  /** default: 18 */
  data_labels_font_size?: number;
  /** Цвет значений над элементами */
  /** default: "#fff", белый */
  data_labels_color?: string;
  /** Включение функции подсветки выделенного элемента' */
  /** default: true */
  is_hover_active?: boolean;
  /** Включение подсказки */
  /** default: true */
  is_tooltip_enabled?: boolean;
  /** Включение лейблов */
  /** default: false */
  is_labels_enabled?: boolean;
  /** Положение лейблов над диаграммой */
  /** default: center */
  labels_position?: "start" | "center" | "end";
};

const DonutChart: React.FC<DonutChartProps> = ({
  title,
  radius,
  cutout,
  labels,
  datasets,
  not_displayed_data,
  center_text,
  center_text_font_size,
  aspect,
  is_data_labels_enabled,
  data_labels_description,
  data_labels_font_size,
  data_labels_color,
  is_hover_active = true,
  is_tooltip_enabled,
  is_labels_enabled,
  labels_position,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const fontFamily = "Nunito Sans";

  const preparingColors = (colors: string[]): string[] => {
    return is_hover_active
      ? activeIndex === null
        ? colors
        : colors.map((color, index) =>
            activeIndex === index ? color : "#f0f1f5"
          )
      : colors;
  };

  const preparedDatasets = datasets.map((dataset) => {
    return {
      data: dataset.data,
      backgroundColor: preparingColors(dataset.color),
      borderWidth: cutout ? 2 : 1,
    };
  });

  const data = {
    labels: labels,
    datasets: preparedDatasets,
  };

  const options = {
    legend: {
      display: false,
    },
    aspectRatio: aspect ? aspect : 1,
    radius: radius,
    cutout: cutout ? cutout : "0%",
    plugins: {
      title: {
        display: !!title,
        text: title,
        font: {
          size: 18,
          family: fontFamily,
        },
      },
      legend: {
        display: is_labels_enabled ? true : false,
        align: labels_position,
        labels: {
          font: {
            size: 15,
            family: fontFamily,
            weight: "bold",
          },
          color: "#272d34",
          boxWidth: 18,
          boxHeight: 18,
        },
      },
      tooltip: {
        enabled: is_tooltip_enabled,
        backgroundColor: "#fff",
        position: "nearest" as const, // "average" | "nearest"
        // yAlign: "center" as const, // "center" | "top" | "bottom"
        // xAlign: "right" as const, // "center" | "left" | "right"
        titleFont: {
          size: 14,
          family: fontFamily,
        },
        bodyFont: {
          size: 12,
          family: fontFamily,
        },
        titleColor: "#272d34",
        bodyColor: "#272d34",
        titleAlign: "center" as const,
        bodyAlign: "center" as const,
        borderColor: "#bcbfcc",
        borderWidth: 1,
        displayColors: false,
        callbacks: {
          label: (tooltipItem: TooltipItem<"doughnut">) => {
            const tooltips = datasets[tooltipItem.datasetIndex].tooltip;
            const value = tooltips ? tooltips[tooltipItem.dataIndex] : "";
            return value;
          },
        },
      },
      datalabels: {
        display: (context: Context) => {
          const value = context.dataset.data[context.dataIndex];
          const numericValue = Array.isArray(value)
            ? value[0]
            : (value as number);
          return (
            !!is_data_labels_enabled &&
            !not_displayed_data?.includes(numericValue)
          );
        },
        color: data_labels_color ?? "#fff",
        font: {
          size: data_labels_font_size ?? (18 as const),
          family: fontFamily,
          weight: "bold" as const,
        },
        align: cutout ? ("center" as const) : ("end" as const),
        formatter: (value: number) =>
          data_labels_description
            ? `${value}${data_labels_description}`
            : value,
      },
    },
    onHover: is_hover_active
      ? (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
          if (elements.length) {
            const index = elements[0].index;
            setActiveIndex(index);
          } else {
            setActiveIndex(null);
          }
        }
      : undefined,
  };

  const centerTextPlugin = {
    id: "centerTextPlugin",
    beforeDraw: (chart: Chart) => {
      if (center_text) {
        const {
          ctx,
          chartArea: { width, height },
        } = chart;
        ctx.save();
        const text = center_text;
        ctx.font = `700 ${center_text_font_size ?? 24}px Nunito Sans`;
        ctx.fillStyle = "#626673";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(
          text,
          width / 2 + chart.chartArea.left,
          height / 2 + chart.chartArea.top
        );
        ctx.restore();
      }
    },
  };

  const plugins = [centerTextPlugin];

  return <Doughnut data={data} options={options} plugins={plugins} />;
};

export default React.memo(DonutChart);
