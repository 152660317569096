import { Form, FormikProvider, useFormik } from "formik";
import styles from "./Form.module.css";
import { TextArea } from "../Components";
import NewsBlock from "../../components/NewsBlock/NewsBlock";
import Button from "../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { News } from "../../types/content-types";
import { createNews, getNews, updateNews } from "../../common/ApiService";
import { useEffect, useState } from "react";
import CircleLoader from "../../components/Loaders/CircleLoader/CircleLoader";
import {
  convertDateToISOString,
  formatDateTime,
  isValidDateFormat,
} from "../../common/utils";

const defaultValues: News = {
  header: "",
  created: formatDateTime(new Date().toISOString().slice(0, 10), "ddmmyyyy"),
  content: "",
};

export default function AddNewsForm() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const formik = useFormik({
    initialValues: defaultValues,
    onSubmit: (values) => {
      const prepData = prepareData(values);
      if (values.id) {
        setIsLoading(true);
        updateNews(prepData).then(() => {
          navigate("/management/news");
        });
      } else {
        setIsLoading(true);
        createNews(prepData).then(() => {
          navigate("/management/news");
        });
      }
    },
  });

  const { values, handleChange, setValues } = formik;

  useEffect(() => {
    const fetchInitialValues = async () => {
      if (id) {
        try {
          const news = await getNews(id);
          news.created = formatDateTime(news.created, "ddmmyyyy");
          setValues(news);
        } catch (error) {
          console.error("Error fetching initial values:", error);
          setValues(defaultValues);
        }
      } else {
        setValues(defaultValues);
      }
      setIsLoading(false);
    };

    fetchInitialValues();
  }, [id, setValues]);

  function prepareData(values: News) {
    return {
      ...values,
      created: convertDateToISOString(values.created),
    };
  }

  const header = !id ? "Создание новости" : "Редактирование новости";
  const buttonTitle = !id ? "Опубликовать" : "Сохранить изменения";

  const isValid = !!(
    values.header &&
    isValidDateFormat(values.created) &&
    values.content
  );

  return (
    <div className={styles.modalFormContainer}>
      {isLoading && <CircleLoader />}
      <div className={styles.container} style={{ width: 820 }}>
        <NewsBlock {...values} />
      </div>

      <FormikProvider value={formik}>
        <Form>
          <div className={styles.container} style={{ width: 600 }}>
            <h4 className={styles.heading}>{header}</h4>
            <TextArea
              name="header"
              label="Заголовок"
              onChange={handleChange}
              value={values.header}
            />
            <TextArea
              name="created"
              label="Дата"
              placeholder="Введите дату формата дд.мм.гггг"
              onChange={handleChange}
              value={values.created}
              rows={1}
            />
            <TextArea
              name="content"
              label="Контент с версткой"
              onChange={handleChange}
              value={values.content}
              rows={32}
            />
            <div className={styles.buttonContainer}>
              <Button
                type="submit"
                title={buttonTitle}
                status={isValid ? "enabled" : "disabled"}
              />
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
}
