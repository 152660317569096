import { BarChartProps } from "../components/Charts/BarChart/BarChart";
import { DonutChartProps } from "../components/Charts/DonutChart/DonutChart";

export const contentBlockTypes = [
  { id: 10, title: "Текст: заголовки, параграфы" },
  { id: 20, title: "Легенда - отдельные объекты" },
  { id: 30, title: "Легенда горизонтальная" },
  {
    id: 40,
    title:
      "Изображение (в том числе и диаграммы, доступные по ссылке на изображение)",
  },
  { id: 80, title: "Шкала непрерывная" },
  { id: 81, title: "Шкала с разрывами" },
  { id: 82, title: "Шкала круговая" },
  { id: 100, title: "Диаграмма столбчатая " },
  { id: 101, title: "Диаграмма круговая донат" },
  { id: 102, title: "Диаграмма круговая пайчарт" },
];

export const nestedContentBlockTypes = [{ id: 1, title: "Вложенные блоки" }];

export const messages = {
  empty: "Не должно быть пустым",
  codeFormat: "Неверный формат кода (12-34)",
  invalidJson: "Некорректный JSON",
  fileEmpty: "Файл обязателен",
};

export const barChartDefaultRowData = `{  
  "title": "Доля сельского населения",
  "is_horizontal": false,
  "legends": ["Оренбург", "Поволжье"],
  "datasets": [
    {
      "label": "Доля населения",
      "data": [40.3, 27.3],
      "color": ["#1032a1", "#2861f7"],
      "tooltip": ["Население", "Население"]
    }
  ],
  "is_hover_active": true,
  "aspect": 1,
  "bar_percentage": 0.7,
  "is_bar_labels_disabled": false,
  "bar_labels_font_size": 15,
  "legend_font_size": 14,
  "data_labels_description": "%",
  "is_default_bar_labels_color": false,
  "is_tooltip_enabled": true,
  "is_labels_enabled": false,
  "labels_position": "center"
}`;

export const barChartDefaultData: BarChartProps = {
  title: "Доля сельского населения",
  is_horizontal: false,
  legends: ["Оренбург", "Поволжье"],
  datasets: [
    {
      label: "Доля населения",
      data: [40.3, 27.3],
      color: ["#1032a1", "#2861f7"],
      tooltip: ["Население", "Население"],
    },
  ],
  is_hover_active: true,
  aspect: 1,
  bar_percentage: 0.7,
  is_bar_labels_disabled: false,
  bar_labels_font_size: 15,
  legend_font_size: 14,
  data_labels_description: "%",
  is_default_bar_labels_color: false,
  is_tooltip_enabled: true,
  is_labels_enabled: false,
  labels_position: "center",
};

export const donutChartDefaultRowData = `{  
  "title": "Регионы-лидеры по доли пассажиропотока, %",
  "radius": 160,
  "cutout": "65%",
  "labels": ["Москва", "Краснодарский край", "Ростовская обл.", "Московская обл.", "Санкт-Петербург", "Прочие"],
  "datasets": [
    {
      "data": [26, 3, 15, 19, 1, 36],
      "color": ["#005c51", "#007b70", "#079387", "#08a99b", "#08a395", "#9d9d9d"],
      "tooltip": ["26%", "3%", "15%", "19%", "1%", "36%"]
    }
  ],
  "not_displayed_data": [3, 1],
  "center_text": "100%",
  "center_text_font_size": 40,
  "aspect": 1,
  "is_data_labels_enabled": true,
  "data_labels_description": "%",
  "data_labels_font_size": 18,
  "data_labels_color": "#fff",
  "is_hover_active": true,
  "is_tooltip_enabled": true,
  "is_labels_enabled": true,
  "labels_position": "center"  
}`;

export const donutChartDefaultData: DonutChartProps = {
  title: "Регионы-лидеры по доли пассажиропотока, %",
  radius: 160,
  cutout: "65%",
  labels: [
    "Москва",
    "Краснодарский край",
    "Ростовская обл.",
    "Московская обл.",
    "Санкт-Петербург",
    "Прочие",
  ],
  datasets: [
    {
      data: [26, 3, 15, 19, 1, 36],
      color: ["#005c51", "#007b70", "#079387", "#08a99b", "#08a395", "#9d9d9d"],
      tooltip: ["26%", "3%", "15%", "19%", "1%", "36%"],
    },
  ],
  not_displayed_data: [3, 1],
  center_text: "100%",
  center_text_font_size: 40,
  aspect: 1,
  is_data_labels_enabled: true,
  data_labels_description: "%",
  data_labels_font_size: 18,
  data_labels_color: "#fff",
  is_hover_active: true,
  is_tooltip_enabled: true,
  is_labels_enabled: true,
  labels_position: "center",
};

export const pieChartDefaultRowData = `{  
  "title": "Доля иностранных туристов, %",
  "radius": 160,
  "labels": ["Азербайджан", "Турция", "Узбекистан", "Грузия", "Украина", "Эстония", "Прочие"],
  "datasets": [
    {
      "data": [21, 18, 12, 8, 2, 1, 38],
      "color": ["#0a1d3e", "#22304a", "#1f3564", "#5a72b2", "#6e84b3", "#6d7c9d", "#c1cde5"],
      "tooltip": ["21%", "18%", "12%", "8%", "2%", "1%", "38%"]
    }
  ],
  "not_displayed_data": [1, 2],
  "aspect": 1,
  "is_data_labels_enabled": true,
  "data_labels_description": "%",
  "data_labels_font_size": 18,
  "data_labels_color": "#fff",
  "is_hover_active": true,
  "is_tooltip_enabled": true,
  "is_labels_enabled": true,
  "labels_position": "center"  
}`;

export const pieChartDefaultData: DonutChartProps = {
  title: "Доля иностранных туристов, %",
  radius: 160,
  labels: [
    "Азербайджан",
    "Турция",
    "Узбекистан",
    "Грузия",
    "Украина",
    "Эстония",
    "Прочие",
  ],
  datasets: [
    {
      data: [21, 18, 12, 8, 2, 1, 38],
      color: [
        "#0a1d3e",
        "#22304a",
        "#1f3564",
        "#5a72b2",
        "#6e84b3",
        "#6d7c9d",
        "#c1cde5",
      ],
      tooltip: ["21%", "18%", "12%", "8%", "2%", "1%", "38%"],
    },
  ],
  not_displayed_data: [1, 2],
  aspect: 1,
  is_data_labels_enabled: true,
  data_labels_description: "%",
  data_labels_font_size: 18,
  data_labels_color: "#fff",
  is_hover_active: true,
  is_tooltip_enabled: true,
  is_labels_enabled: true,
  labels_position: "center",
};
