import React from "react";
import styles from "./../BaseChart.module.css";
import lStile from "./Additional.module.css";
import { Block } from "../../../types/content-types";
function SolidGradientScale({ contentBlock }: { contentBlock: Block }) {
  const { x_data } = contentBlock;

  return (
    <div className={styles.diagramContainer}>
      <h4>{contentBlock.header}</h4>
      {contentBlock.sub_header && <h5>{contentBlock.sub_header}</h5>}
      <div
        style={
          contentBlock.style && {
            background: contentBlock.style?.fill,
          }
        }
        className={styles.gradientLine}
      >
        <div className={lStile.divisionMarkStrip}>
          {x_data?.map((num) => (
            <div className={lStile.division} key={num}>
              <div className={lStile.divisionMark}></div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.numbersContainer}>
        {x_data?.map((num) => <div key={num}>+{num}</div>)}
      </div>
      {contentBlock.description && <p>{contentBlock.description}</p>}
    </div>
  );
}

export default React.memo(SolidGradientScale);
