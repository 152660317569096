import React, { useEffect, useState } from "react";
import styles from "./NewsManagement.module.scss";
import classNames from "classnames";
import { News } from "../../../types/content-types";
import { orderBy } from "lodash";
import ButtonThreeDot from "../../ButtonThreeDot/ButtonThreeDot";
import { deleteNews, getNewsList } from "../../../common/ApiService";
import { formatDateTime } from "../../../common/utils";
import CircleLoader from "../../Loaders/CircleLoader/CircleLoader";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";

const NewsManagement: React.FC = () => {
  const [news, setNews] = useState<News[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof News;
    direction: "asc" | "desc";
  }>({
    key: "created",
    direction: "desc",
  });

  const navigate = useNavigate();

  const fetchNews = async () => {
    try {
      setIsLoading(true);
      const newsList = await getNewsList();
      setIsLoading(false);
      setNews(newsList);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  const headers = {
    created: "Дата новости",
    header: "Заголовок новости",
    updated: "Дата изменений",
  };

  const options = {
    edit: "Редактировать",
    remove: "Удалить новость",
  };

  const sortedNews = orderBy(news, sortConfig.key, sortConfig.direction);

  const handleSort = (field: keyof News) => {
    setSortConfig((prev) => {
      if (prev.key === field) {
        return {
          key: field,
          direction: prev.direction === "asc" ? "desc" : "asc",
        };
      } else {
        return { key: field, direction: "asc" };
      }
    });
  };

  const handleSelectAction = (news: News, option: string) => {
    switch (option) {
      case "remove":
        if (news.id) {
          setIsLoading(true);
          deleteNews(news.id).then(() => fetchNews());
        }
        break;

      case "edit":
        if (news.id) {
          navigate(`/edit/news/${news.id}`);
        }
        break;
    }
  };

  const handleCreate = () => {
    navigate("/edit/news/");
  };

  return (
    <>
      {isLoading && <CircleLoader />}

      <div className={styles.titleContainer}>
        <div className={styles.mainTitle}>Управление новостями</div>
        <Button title="Создать новость" onClick={handleCreate} />
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            {Object.entries(headers).map(([field, title]) => (
              <th key={field}>
                <div
                  className={styles.thDiv}
                  onClick={() => handleSort(field as keyof News)}
                >
                  <p>{title}</p>
                  <div
                    className={classNames(styles.arrow, {
                      [styles.activeAsc]:
                        field === sortConfig.key &&
                        sortConfig.direction === "asc",
                      [styles.activeDesc]:
                        field === sortConfig.key &&
                        sortConfig.direction === "desc",
                    })}
                  />
                </div>
              </th>
            ))}
            <th>
              <div
                className={styles.thLastDiv}
                style={{ border: "none", paddingRight: 16 }}
              >
                Действия
              </div>
            </th>
          </tr>
        </thead>
        {!!sortedNews.length && (
          <tbody>
            {sortedNews.map((news, index) => (
              <tr key={index}>
                <td>{formatDateTime(news.created, "ddmmyyyy")}</td>
                <td>{news.header}</td>
                <td>{formatDateTime(news.updated)}</td>
                <td>
                  <div className={styles.buttonContainer}>
                    <ButtonThreeDot
                      options={options}
                      onSelect={(option) => handleSelectAction(news, option)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </>
  );
};

export default NewsManagement;
