import React from "react";
import styles from "./SolidScale.module.scss";
import { ContentBlock } from "../../../types/content-types";
import ContentBlockBase from "../../ContentBlockBase";

function SolidScale({ contentBlock }: { contentBlock: ContentBlock }) {
  if (contentBlock?.scale) {
    const { data, legend } = contentBlock?.scale;
    return (
      <ContentBlockBase contentBlock={contentBlock}>
        <div className={styles.columnContainer}>
          <div className={styles.dataContainer}>
            {data.map((element, index: number) => (
              <div
                key={index}
                className={styles.element}
                style={{ backgroundColor: element.color }}
              />
            ))}
          </div>
          <div className={styles.labelContainer}>
            {legend.map((item, index: number) => (
              <div key={index} className={styles.label}>
                {item.title}
              </div>
            ))}
          </div>
        </div>
      </ContentBlockBase>
    );
  }
  return null;
}

export default React.memo(SolidScale);
