import React, {useCallback}from 'react';
import ReactDOMServer from 'react-dom/server';
import { RLayerVector } from 'rlayers';
import { FeatureLike } from 'ol/Feature';
import IconStyle from 'ol/style/Icon';
import Style from 'ol/style/Style';
import TwoCircles from '../elements/pie/TwoCircles';
import { GeoJSON } from 'ol/format';
import { Layer } from "../config/interfaces";
import { getPieData, calculateSquareSide } from "../utils";

interface PointLayerProps {
  url: string;
  layer: Layer;
  order: number;
}

const EulerDiagramLayer: React.FC<PointLayerProps> = ({ url, layer, order }) => {

  const createSVGIconStyleCallback = useCallback(
    (feature: FeatureLike) => {
      if (layer.marker) {

        const pieData = getPieData(feature.getProperties(), layer.marker);
        
        let size = Number(pieData.size) * Number(pieData.size_multiplication);
        const valuesRange = layer.marker?.values_range;
        if (valuesRange && layer.marker?.size) {
            size = calculateSquareSide(Number(pieData.size), valuesRange);
        }
  
        let chartSVG = ReactDOMServer.renderToString(
          <TwoCircles 
            svgSize={size} 
            outerRadius={pieData.sectors[0].value} 
            innerRadius={pieData.sectors[1].value} 
            outerColor={pieData.sectors[0].color} 
            innerColor={pieData.sectors[1].color} />
        );
        const svgDataUri = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(chartSVG)}`;
  
        return new Style({
          image: new IconStyle({
            src: svgDataUri,
            scale: 1,
            anchor: [0.5, 0.5],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
          }),
        });
      }
      return new Style({
        image: new IconStyle({
          src: "",
          scale: 1,
          anchor: [0.5, 0.5],
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
        }),
      });
    },
    [layer.marker]
  );

  return (
    <RLayerVector
      zIndex={order}
      format={new GeoJSON({ featureProjection: "EPSG:4326" })}
      url={url}
      visible={layer.visible}
      style={createSVGIconStyleCallback}
    />
  );
};

export default EulerDiagramLayer;
