import { areaCulc, diameterCulc } from "../../../common/utils";

export function calculateMinMaxPieSize(
    max_value: number, 
    size: "auto" | "small" | "medium" | "large") {
    const calcMax = max_value >= 300 ? 300 : max_value >= 100 ? 200 : 100;
    const optionsMax = {
        auto: calcMax,
        small: 100,
        medium: 200,
        large: 300,
      };
    
    const max_size = optionsMax[size];
    const min_size = 20;
    
    const areaMax = areaCulc(max_size);
    const areaMin = areaCulc(min_size);

    return { min_size, max_size }

}

export function calculatePieSize(
    value: number,
    input_min: number,
    input_max: number,
    min_value: number,
    max_value: number
): number {
    if (input_min === input_max) {
        throw new Error("input_min и input_max не должны быть равны (деление на ноль)");
    }

    // Нормализация value в диапазон [min_value, max_value]
    const scaled_diameter = min_value + ((value - input_min) / (input_max - input_min)) * (max_value - min_value);

    // Вычисляем площадь по этому диаметру
    const area = areaCulc(scaled_diameter);

    // Вычисляем диаметр для итоговой диаграммы
    return diameterCulc(area);
}