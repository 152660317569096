const A_min = 10 ** 2; // 100

export function calculateSquareSide(value: number, valuesRange: number[], maxSide?: number | undefined) {
    const v_min = Math.min(...valuesRange);
    const v_max = Math.max(...valuesRange);

    // Вычисляем площадь пропорционально значению, без ограничения максимального размера
    let area = A_min + ((value - v_min) * (A_min * 100)) / (v_max - v_min);

    if (maxSide) {
      const A_max = maxSide ** 2; // Максимальная площадь, соответствующая maxSide
      // Вычисляем площадь, масштабированную в диапазоне [A_min, A_max]
      area = A_min + ((value - v_min) * (A_max - A_min)) / (v_max - v_min);

      // Возвращаем сторону квадрата, но не больше maxSide
      return Math.min(Math.sqrt(area), maxSide);
    }
    
    // Возвращаем сторону квадрата
    return Math.round(Math.sqrt(area));
  }
