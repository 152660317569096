import React, { memo, useEffect, useRef, useState } from "react";
import Globe, { GlobeMethods } from "react-globe.gl";
import styles from "./globe.module.css";
import { poligonsData } from "./polygonsData";
import mapTexture from "./MAP_TEXTURE.jpg";
import { useDispatch, useSelector } from "react-redux";
import { setClusterId } from "../../redux/map";
import { useSidebar } from "../SideBar/useSidebar";
import { RootState } from "../../store";
import { useWindowSize } from "@react-hook/window-size";

function findPolygonByClusterId(clusterId: string) {
  return poligonsData.features.find(
    (feature) => feature.clusterId === clusterId
  );
}

const initalCoordinates = { lat: 55.7558, lng: 37.6176, altitude: 1 };

function flyToCluster(
  polygon: any,
  globeEl: any,
  animationRef: any,
  clusterId?: string
) {
  if (globeEl.current?.pointOfView) {
    if (clusterId) {
      polygon = findPolygonByClusterId(clusterId);
    }

    const { coordinates } = polygon.geometry;
    const [lng, lat] = coordinates[0][0];

    globeEl.current.pointOfView({ lat, lng, altitude: 0.5 }, 1500);
    cancelAnimationFrame(animationRef.current);
  }
}

function GlobeMap() {
  const [hoveredPolygon, setHoveredPolygon] = useState(null);
  const globeMethods = useRef<GlobeMethods>();
  const animationRef = useRef<number | null>(null);
  const isRotating = useRef(true);
  const dispatch = useDispatch();

  const { clusterId } = useSidebar();

  const { isSidebarOpen } = useSelector((state: RootState) => state.menu);

  const w = window.innerWidth;
  const shiftFactor = isSidebarOpen ? 0.16 : 0;
  const shiftAmmount = shiftFactor * w;

  const [width, height] = useWindowSize();

  const getPolygonColor = (polygon: any) => {
    if (polygon.clusterId === clusterId) {
      return "rgba(0, 44, 210, 0.9)";
    }
    if (polygon === hoveredPolygon) {
      return "rgba(0, 44, 210, 0.9)";
    }
    return "rgba(0, 44, 210, 0.7)";
  };

  const handlePolygonHover = (polygon: any) => {
    if (animationRef.current) {
      if (polygon) {
        isRotating.current = false;
        setHoveredPolygon(polygon);
      } else {
        isRotating.current = true;
      }
    }
  };

  const handlePolygonClick = (polygon: any) => {
    dispatch(setClusterId(polygon.clusterId));

    isRotating.current = false;
    flyToCluster(polygon, animationRef, globeMethods);
  };

  useEffect(() => {
    if (globeMethods.current) {
      globeMethods.current.pointOfView(initalCoordinates);

      const startPosition = [55.7558, 37.6176];
      const resetLongitude = 92;
      let direction = 1;
      let before: any = null;

      const animate = (now: any) => {
        if (globeMethods.current) {
          const elapsed = before ? now - before : 0;
          before = now;

          const newLongitude = isRotating.current
            ? globeMethods.current.pointOfView().lng +
              direction * 0.1 * (elapsed / 90)
            : globeMethods.current.pointOfView().lng;

          if (
            newLongitude >= resetLongitude ||
            newLongitude <= startPosition[1]
          ) {
            direction *= -1;
          }

          globeMethods.current?.pointOfView({
            lat: startPosition[0],
            lng: newLongitude,
            altitude: 0.75,
          });

          animationRef.current = requestAnimationFrame(animate);
        }
      };

      animationRef.current = requestAnimationFrame(animate);
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (clusterId) flyToCluster(null, globeMethods, animationRef, clusterId);
  }, [clusterId]);

  return (
    <div
      className={styles.globeViz}
      style={{
        marginLeft: `-${shiftAmmount}px`,
        transition: "all 1s ease-out",
      }}
    >
      <Globe
        height={height}
        width={width}
        ref={globeMethods}
        globeImageUrl={mapTexture}
        backgroundColor="#FFFFFF"
        polygonsData={poligonsData.features}
        polygonCapColor={getPolygonColor}
        polygonSideColor={"rgba(255, 255, 255, 1)"}
        polygonStrokeColor={"#FFFFFF"}
        polygonLabel={({ properties }: any) =>
          `<div style="position: relative;">
      <div style="background: rgba(39, 45, 52); color: white; padding: 5px; border-radius: 0px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); font-size: 18px; font-weight: bold; text-align: center; width: 100%;">
        ${properties.name}
      </div>
    </div>`
        }
        polygonsTransitionDuration={0}
        polygonAltitude={0.009}
        atmosphereColor="rgba(100, 103, 114, 0.01)"
        atmosphereAltitude={0.2}
        onPolygonClick={handlePolygonClick}
        onPointHover={handlePolygonHover}
      />
    </div>
  );
}

export default memo(GlobeMap);
