import React, {useCallback}from 'react';
import ReactDOMServer from 'react-dom/server';
import { RLayerVector } from 'rlayers';
import { FeatureLike } from 'ol/Feature';
import IconStyle from 'ol/style/Icon';
import Style from 'ol/style/Style';
import PieChart from '../elements/pie/PieCharSVG';
import { GeoJSON } from 'ol/format';
import { Layer } from "../config/interfaces";
import { getPieData, calculateMinMaxPieSize, calculatePieSize } from "../utils";
import { FeatureCollection } from 'geojson';

interface PointLayerProps {
  url: string;
  layer: Layer;
  order: number;
  geojson: FeatureCollection;
  min: number,
  max: number,
}

const PieIconLayer: React.FC<PointLayerProps> = ({ url, layer, order, geojson, min, max }) => {

  console.log("min, max", min, max);

  const { min_size, max_size } = calculateMinMaxPieSize(max, 'auto');

  const createSVGIconStyleCallback = useCallback(
    (feature: FeatureLike) => {
      if (layer.marker) {
        const pieData = getPieData(feature.getProperties(), layer.marker);
        
        const pie_size = calculatePieSize(Number(pieData.size), min, max, min_size, max_size);

        let chartSVG = ReactDOMServer.renderToString(
          <PieChart data={pieData.sectors} radius={pie_size / 2}/>
        );
        const svgDataUri = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(chartSVG)}`;
  
        return new Style({
          image: new IconStyle({
            src: svgDataUri,
            scale: 1,
            anchor: [0.5, 0.5],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
          }),
        });
      }
      return new Style({
        image: new IconStyle({
          src: "",
          scale: 1,
          anchor: [0.5, 0.5],
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
        }),
      });
    },
    [layer.marker]
  );

  return (
    <RLayerVector
      zIndex={order}
      format={new GeoJSON({ featureProjection: "EPSG:4326" })}
      url={url}
      visible={layer.visible}
      style={createSVGIconStyleCallback}
    />
  );
};

export default PieIconLayer;
