import "ol/ol.css";
import { fromLonLat } from "ol/proj";
import { register } from "ol/proj/proj4.js";
import proj4 from "proj4";
import styles from "./Map.module.css";
import React, { useRef, useEffect, useState, useMemo, useCallback } from "react";
import View from "ol/View";
import * as olProj from "ol/proj";
import { useDispatch, useSelector } from "react-redux";
import { selectMap } from "../../redux/map/selectors";
import { RControl, RMap, RLayerTileWebGL, RLayerWMS } from "rlayers";
import ButtonScreenshot from "../ButtonScreenshot/ButtonScreenshot";
import { useConstructorMode } from "../../redux/constructorMode";
import MapLoader from "../Loaders/MapLoader/MapLoader";
import { deselect, setSelectedFeature, setFeatureInfoWindow, setInfoWindowFeatureOffset, selectCluster, deselectCluster } from "../../redux/map/featureReducer";
import { getFeatureInfowindow, getFeatureMarker, calculateInfowindowOffset } from "./utils";
import WFSLayer from "./WFSLayer";
import ZoomHomeButton from "../MapControls/ZoomHomeButton";
import DefaultLayer from "./wms/DefaultLayers";
import { MapBrowserEvent } from "ol";
import { FeatureLike } from "ol/Feature";
import { Geometry, Point } from "ol/geom";
import { Feature } from "ol";
import { InfoWindowOnClick } from "./InfoWindowOnClick";
import { title } from "process";

export default function Map() {
  const dispatch = useDispatch();
  const { map_config } = useSelector(selectMap);
  const isConstructorMode = useSelector(useConstructorMode);
  
  const mapRef = useRef<RMap | null>(null);
  const mapRefContainer = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    proj4.defs("EPSG:3576", "+proj=laea +lat_0=90 +lon_0=90 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs +type=crs");
    register(proj4);
  }, []);

  const mapProjection = map_config?.projection || "EPSG:3857";
  const initialView = useMemo(() => ({
    center: fromLonLat(map_config?.center || [30, 40]),
    zoom: map_config?.zoom.start || 3,
    minZoom: map_config?.zoom.min || 1,
    maxZoom: map_config?.zoom.max || 24,
  }), [map_config]);

  const layersMemo = useMemo(() => map_config?.layers || [], [map_config?.layers]);

  const updateMapView = useCallback(() => {
    const mapInstance = mapRef.current;
    if (!mapInstance) return;

    const map = mapInstance.ol;
    if (!map) return;

    const view = map.getView();
    if (!isConstructorMode) {
      view.setZoom(map_config?.zoom.start || 3);
      view.setCenter(fromLonLat(map_config?.center || [30, 40]));
    }
  }, [map_config, isConstructorMode]);

  useEffect(() => {
    updateMapView();
  }, [updateMapView]);

  useEffect(() => {
    const map = mapRef.current?.ol;
    if (!map) return;
  
    const newProjection = olProj.get(mapProjection);
    if (!newProjection) return;
  
    const view = new View({
      projection: newProjection,
      center: olProj.fromLonLat(map_config?.center || [30, 40], newProjection),
      zoom: map_config?.zoom.start || 3,
    });
  
    map.setView(view);
  }, [mapProjection, map_config]);
  

  const handleMapClick = (event: MapBrowserEvent<UIEvent>) => {
    const map = mapRef.current?.ol;
    if (!map) return;
  
    const featureLike = map.forEachFeatureAtPixel(event.pixel, (feat: FeatureLike) => feat);
    if (!(featureLike instanceof Feature)) {
      dispatch(deselect());
      return;
    }
  
    let feature = featureLike as Feature<Geometry>;
    const clusterFeatures = feature.get("features");

    const view = map.getView();
    const zoom = view.getZoom();
  
    if (Array.isArray(clusterFeatures) && clusterFeatures.length > 1) {
      const avgCoordinate = clusterFeatures.reduce(
        (acc: [number, number], f: Feature<Geometry>) => {
          const geom = f.getGeometry();
          if (geom instanceof Point) {
            const coords = geom.getCoordinates();
            acc[0] += coords[0];
            acc[1] += coords[1];
          }
          return acc;
        },
        [0, 0]
      ).map((val: number) => val / clusterFeatures.length);
  
      if (!avgCoordinate || avgCoordinate.some(isNaN)) {
        console.error("Не удалось вычислить координаты для анимации.");
        return;
      }
      
      // продолжаем подлёт только до 20 зума
      if (zoom && zoom < 20) {
        map.getView().animate({
          center: avgCoordinate,
          zoom: (map.getView().getZoom() || 0) + 1,
          duration: 500,
        });
      }
  
      if (avgCoordinate && zoom && zoom > 18 && clusterFeatures.length === 2) {   
        feature = clusterFeatures[1];
        const featureInfowindow = getFeatureInfowindow(feature, map_config?.layers);
        // создаём геометрию
        const point = new Point(avgCoordinate);

        // создаём фичу
        const featureCluster = new Feature({
          geometry: point
        });

        dispatch(selectCluster(clusterFeatures));
        dispatch(setInfoWindowFeatureOffset(16));
        dispatch(setSelectedFeature(featureCluster));
        dispatch(setFeatureInfoWindow(featureInfowindow));

      }

      return;
    }
  
    if (Array.isArray(clusterFeatures) && clusterFeatures.length === 1) {
      feature = clusterFeatures[0];
    }
  
    const featureInfowindow = getFeatureInfowindow(feature, map_config?.layers);
    const { geometryType, featureMarker } = getFeatureMarker(feature, map_config?.layers);
  
    if (["diagramm", "pie"].includes(geometryType as string) && featureMarker?.size) {
      dispatch(setInfoWindowFeatureOffset(calculateInfowindowOffset(geometryType as string, featureMarker, feature)));
    }
    dispatch(deselectCluster());
    dispatch(setSelectedFeature(feature));
    dispatch(setFeatureInfoWindow(featureInfowindow));
  };
  


  useEffect(() => {
    const map = mapRef.current?.ol;
    if (!map) return;

    map.on("click", handleMapClick);
    return () => {
      map.un("click", handleMapClick);
    };
  }, [map_config, dispatch]);

  useEffect(() => {
    const map = mapRef.current?.ol;
    if (!map) return;
  
    const handlePointerMove = (event: MapBrowserEvent<UIEvent>) => {
      const mapTarget = map.getTarget();
      const feature = map.forEachFeatureAtPixel(event.pixel, (feat: FeatureLike) => feat);
  
      if (mapTarget instanceof HTMLElement) {
        mapTarget.style.cursor = feature ? "pointer" : "";
      }
    };
  
    map.on("pointermove", handlePointerMove);
  
    return () => {
      map.un("pointermove", handlePointerMove);
    };
  }, [map_config]);

  useEffect(() => {
    dispatch(deselect());
  }, [map_config]);

  useEffect(() => {
    const map = mapRef.current?.ol;
    if (!map) return;
  
    const view = map.getView();
    if (!view) return;
  
    view.setMinZoom(map_config?.zoom.min || 1);
    view.setMaxZoom(map_config?.zoom.max || 24);
  }, [map_config]);


  return (
    <>
      {isConstructorMode && <ButtonScreenshot elementRef={mapRefContainer.current} />}
      <div className={styles.mapWrap} ref={mapRefContainer}>
        {isLoading && <MapLoader />}
        <ZoomHomeButton onClick={updateMapView} />
        <RMap
          key={mapProjection}
          ref={mapRef}
          width="100%"
          height="100%"
          projection={mapProjection}
          enableRotation={false}
          initial={initialView}
          minZoom={map_config?.zoom.min || 1}
          maxZoom={map_config?.zoom.max || 24}
          noDefaultControls
          onRenderComplete={() => setIsLoading(false)}
        >
          <RControl.RZoom className={styles.zoom} />
          <InfoWindowOnClick />
          {mapProjection === "EPSG:3857" ? (
            <>
              <RLayerTileWebGL key={"base_default"} url="https://tiles.touristatlas.ru/tile/{z}/{x}/{y}.png" attributions="Custom layers" />
              {layersMemo.map((layerConfig, index) => (
                <WFSLayer key={layerConfig.layer_id} 
                          url="https://geo.touristatlas.ru/geoserver/" 
                          layer={layerConfig} order={layersMemo.length - index} />
              ))}
              <DefaultLayer />
            </>
          ) : (
            <>
              <RLayerWMS key={"base_azim"} url={map_config?.basemap || ""} params={{ bgcolor: "0xD3D5DA", TRANSPARENT: false }} />
              {layersMemo.map((layerConfig, index) => (
                <WFSLayer key={layerConfig.layer_id} 
                          url="https://geo.touristatlas.ru/geoserver/" 
                          layer={layerConfig} order={layersMemo.length - index} />
              ))}
            </>
          )}
        </RMap>
      </div>
    </>
  );
}
