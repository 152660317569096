import AddPostForm from "../edit/EditorForms/AddPostForm";
import Main from "../components/Map/MapOverLayer";
import AddMapForm from "../edit/EditorForms/AddMapForm";
import AddFileForm from "../edit/EditorForms/AddFileForm";
import MenuButton from "../components/MenuButton/MenuButton";
import AddModalForm from "../edit/EditorForms/AddModalForm";
import { AppDispatch } from "../store";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getMapsListThunk } from "../redux/map";
import { useMenuEdit } from "../hooks/useMenuEditConfig";
import AddNewsForm from "../edit/EditorForms/AddNewsForm";

export default function EditorPage({
  type,
}: {
  type: "addMap" | "addPost" | "file" | "modal" | "news";
}) {
  const { menuEditOptions, handleMenuEditSelect } = useMenuEdit();
  const dispatch = useDispatch<AppDispatch>();

  const forms = {
    addMap: <AddMapForm />,
    addPost: <AddPostForm />,
    file: <AddFileForm />,
    modal: <AddModalForm />,
    news: <AddNewsForm />,
  };

  useEffect(() => {
    dispatch(getMapsListThunk());
  }, [dispatch]);

  return (
    <>
      <MenuButton
        options={menuEditOptions}
        onSelect={handleMenuEditSelect}
        selectedOption={type}
        style={{
          position: "absolute",
          top: "16px",
          left: "16px",
        }}
      />
      <Main>
        <div
          style={{
            pointerEvents: "auto",
            margin: "20px auto",
            borderRadius: "10px",
          }}
        >
          {forms[type]}
        </div>
      </Main>
    </>
  );
}
