import React, { useMemo } from "react";
import { Layer } from "./config/interfaces";
import useWFSLayer from "./wfs/useWFSLayer";

import PointLayer from "./wfs/PointWFSLayer";
import PolygonLayer from "./wfs/PolygonWFSLayer";
import CartogramLayer from "./wfs/CartogramWFSLayer";
import LineLayer from "./wfs/LineWFSLayer";
import PieIconLayer from "./wfs/PieIconWFSLayer";
import SvgLayer from "./wfs/SvgWFSLayer";
import PolygonHatchedLayer from "./wfs/PolygonHatchedWFSLayer";
import PointClusterLayer from "./wfs/PointClusterWFSLayer";
import EulerDiagramLayer from "./wfs/EulerDiagramLayer";
import HalfPieLayer from "./wfs/HalfPieLayer";
import DonatIconLayer from "./wfs/DonatIconWFSLayer";
import CustomRLayerTileWMS from "./wms/CustomWMS";
import ArcLayer from "./wfs/ArcWFSLayer";

interface WFSLayerProps {
  url: string;
  layer: Layer;
  order: number;
}

const WFSLayer: React.FC<WFSLayerProps> = ({ url, layer, order }) => {
  const memoizedLayer = useMemo(() => layer, [JSON.stringify(layer)]);
  let sortByField = null;

  if (layer.geometry_type === "arcline" && layer.marker?.order_field) {
    sortByField = layer.marker.order_field;
  } else if ((layer.geometry_type === "diagramm" || layer.geometry_type === "pie") && 
             layer.marker?.size && typeof layer.marker.size !== "number") {
    sortByField = layer.marker.size;
  }

  const { layer_url, geojson, source, min, max, loading } = useWFSLayer({
    url,
    layer: memoizedLayer,
    sortBy: sortByField,
  });

  const unique_key = layer.layer_id;

  const layerComponents: Record<string, React.ElementType> = {
    wms: CustomRLayerTileWMS,
    points: PointLayer,
    point: PointLayer,
    point_cluster: PointClusterLayer,
    polygon: PolygonLayer,
    polygon_hatched: PolygonHatchedLayer,
    line: LineLayer,
    arcline: ArcLayer,
    cartogram: CartogramLayer,
    pie: PieIconLayer,
    donat: DonatIconLayer,
    half_pie: HalfPieLayer,
    euler: EulerDiagramLayer,
    diagramm: PointLayer,
    svg: SvgLayer,
  };

  const LayerComponent = layerComponents[layer.geometry_type as keyof typeof layerComponents];

  if (!LayerComponent) {
    console.warn(`Unknown layer type: ${layer.geometry_type}`);
    return null;
  }

  // Не рендерим компонент
  if (loading) {
    return null;
  }

  return (
    <LayerComponent
      key={unique_key}
      url={layer_url}
      layer={layer}
      order={order}
      geojson={geojson}
      min={min}
      max={max}
    />
  );
};

export default WFSLayer;
