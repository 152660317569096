import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentZoom: 0,
  isSelected: false,
  feature: null,
  iwindowFeatureOffset: 10,
  featureInfoWindow: null,
  // инфоокно для кластера
  isClusterSelected: false,
  clusterFeatures: null,
};

export const selectedFeatureSlice = createSlice({
  name: "selectFeature",
  initialState,
  reducers: {
    select: (state) => {
      state.isSelected = true;
    },
    deselect: (state) => {
      state.isSelected = false;
      state.feature = null;
      state.featureInfoWindow = null;
      state.iwindowFeatureOffset = 10;
      state.isClusterSelected = false;
    },
    selectCluster: (state, action) => {
      state.isClusterSelected = true;
      state.clusterFeatures = action.payload;
    },
    deselectCluster: (state) => {
      state.isClusterSelected = false;
      state.clusterFeatures = null;
      state.iwindowFeatureOffset = 10;
    },
    setSelectedFeature: (state, action) => {
      state.feature = action.payload;
    },
    setInfoWindowFeatureOffset: (state, action) => {
      state.iwindowFeatureOffset = action.payload;
    },
    setFeatureInfoWindow: (state, action) => {
      state.featureInfoWindow = action.payload;
    },
    setZoom: (state, action) => {
      state.currentZoom = action.payload;
    }
  },
});

export const { select, deselect, 
                selectCluster, deselectCluster,
                setSelectedFeature, 
                setInfoWindowFeatureOffset, setFeatureInfoWindow, setZoom } =
  selectedFeatureSlice.actions;

export default selectedFeatureSlice.reducer;
