import { ContentBlock } from "../../../types/content-types";
import CircleScale from "./CircleScale";
import ContentBlockBase from "../../ContentBlockBase";

export default function CircleScaleBlock({
  contentBlock,
}: {
  contentBlock: ContentBlock;
}) {
  return (
    <ContentBlockBase contentBlock={contentBlock}>
      {contentBlock.circle_scale ? (
        <CircleScale {...contentBlock.circle_scale} />
      ) : (
        <></>
      )}
    </ContentBlockBase>
  );
}
